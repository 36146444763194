// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import "styles/application.css";

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.addEventListener('load', () => {
  document.querySelectorAll('[data-event]').forEach((element) => {
    element.addEventListener('click', (event) => {
      try {
        ga(
          "send",
          "event",
          element.getAttribute('data-event'),
          element.getAttribute('data-event-description')
        );
        console.log('ga event sent');
      } catch (_) {
        console.log('failed send ga event');
      }
    });
  });
});
